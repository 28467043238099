.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes hide {
  from {
    width: 200px;
  }

  to {
    width: 0px;
    opacity: 0;
  }
}

@keyframes reveal {
  from {width: 0}
  to {width: 200px; position: static;}
}

.login-link {
  font-size: 1.6rem;
  color: #ECA564;
}

.login-link:hover {
  color: #F7CE74;
  text-decoration: underline;
}

#operator-link:hover {
  color: #000;
}

.menu-link {
  color: #000;
}

.menu-link:hover {
  color: #E68959;
  cursor: pointer;
}

.error{
  font-size: 1.2rem;
  color: red;
  font-weight: bolder;
}

.success{
  font-size: 1.2rem;
  color: 	#228B22;
  font-weight: bolder;
}

.card-container {
  display: flex;
  padding-top: 5%;
  width: 60%;
  margin: 0 auto;

}

.img-input {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 1e-05;
  pointer-events: none;
}


.img-drop {
  position: relative;
  width: 200px;
  height: 200px;
  border-width: 2px;
  border-color: rgb(102, 102, 102);
  border-style: dashed;
  border-radius: 5px;
  margin: 0 auto;
}

.favIcon {
  color: #e12c2c;
}

.favIcon:hover {
  cursor: pointer
}

.input-container {
  display: flex;
  width: 40%;
  justify-content: flex-end;
  margin: 0;
}

.form-icon {
  border-radius: 25px 0 0 25px;
  border: 3px solid #000;
  background: #000;
  color: #fff;
  height: 3rem;
  min-width: 3rem;
  padding: 0 5px;
}

.search-form {
  width: 0;
}
